// NOTE: the order of these styles DO matter
.DateRangePicker {
  position: absolute !important;

  .DateRangePickerInput__withBorder {
    border: unset;

    .DateInput_input {
      color: $white-color;
      font-size: unset;
      padding: unset;
    }

    .DateInput_input__focused {
      border-bottom: unset;
    }
  }
}

.CalendarMonth_table {
  tr {
    td {
      border: none !important;
      // background-color: #E5F1FF;
    }
  }
  .CalendarDay__selected {
    position: relative;
    background-color: $moderate-blue;
    border-radius: $border-radius50per;
    z-index: 4;
    &:hover {
      background-color: $strong-blue;
    }
    &::after {
      content: "";
      width: 39px;
      height: 39px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
      background-color: #e5f1ff;
      border-radius: $border-radius50per 0 0 $border-radius50per;
    }
    &::before {
      content: "";
      width: 39px;
      height: 39px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: $moderate-blue;
      border-radius: $border-radius50per;
    }
  }
  .CalendarDay__selected_end {
    &::after {
      content: "";
      width: 39px;
      height: 39px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
      background-color: #e5f1ff;
      border-radius: 0 $border-radius50per $border-radius50per 0;
    }
    &::before {
      content: "";
      width: 39px;
      height: 39px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: $moderate-blue;
      border-radius: $border-radius50per;
    }
  }
  .CalendarDay__selected_span {
    color: $moderate-blue;
    background-color: #e5f1ff;
    &:hover {
      background-color: $soft-blue;
    }
    &:active {
      border-radius: none;
    }
  }
}
.DateInput_fang {
  display: none;
}

.DayPicker__withBorder {
  border-radius: $border-radius12px !important;
  box-shadow: 0px 8px 16px 2px rgba(97, 97, 97, 0.1),
    0px 16px 32px 2px rgba(97, 97, 97, 0.1) !important;
}
.DayPickerNavigation_button__horizontalDefault {
  border-radius: $border-radius50per !important;
  background-color: #e5f1ff !important;
  border: none !important;
}
.CalendarDay__hovered_span {
  background-color: #e5f1ff !important;
  color: $moderate-blue !important;
  &:active {
    border-radius: unset;
  }
}
.DayPickerNavigation_leftButton__horizontalDefault {
  text-align: center;
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1sZWZ0IiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjM1NCAxLjY0NmEuNS41IDAgMCAxIDAgLjcwOEw1LjcwNyA4bDUuNjQ3IDUuNjQ2YS41LjUgMCAwIDEtLjcwOC43MDhsLTYtNmEuNS41IDAgMCAxIDAtLjcwOGw2LTZhLjUuNSAwIDAgMSAuNzA4IDB6Ii8+Cjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-position: center;
  color: $moderate-blue;
  svg {
    display: none;
  }
}
.DayPickerNavigation_rightButton__horizontalDefault {
  text-align: center;
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1yaWdodCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjY0NiAxLjY0NmEuNS41IDAgMCAxIC43MDggMGw2IDZhLjUuNSAwIDAgMSAwIC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDgtLjcwOEwxMC4yOTMgOCA0LjY0NiAyLjM1NGEuNS41IDAgMCAxIDAtLjcwOHoiLz4KPC9zdmc+");
  background-repeat: no-repeat;
  background-position: center;

  svg {
    display: none;
  }
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}
.DateRangePicker_picker {
  z-index: 2 !important;
}
.DateRangePicker_picker__directionLeft {
  left: -182.263px !important;
}

