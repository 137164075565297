// standard btn styles
.primary-btn {
  @include primary-btn(
    $white-text,
    $primary-btn-color,
    $moderate-blue,
    $white-text
  );
  @include center-div-flex();
  .icon-wrapper,
  .label-wrapper {
    display: inline-block;
    margin: 0 0.243rem;
  }
  .icon-wrapper {
    i {
      margin-top: unset;
    }
  }
}

.__next__btn__wrapper {
  display: flex;
  align-items: center;
  .__next__btn {
    @include primary-btn(
      $white-color,
      $primary-btn-color,
      $moderate-blue,
      $white-color
    );
    margin: 0 0.8rem;
    .icon--pos {
      position: relative;
      top: 0.15rem;
      margin-left: 0.5rem;
    }
  }
}
.__btn__wrapper {
  display: flex;
  align-items: center;
  .back__btn {
    min-width: 80px;
    max-width: 80px;
    margin-right: 1rem;
    padding: 0.687rem 1rem;
    // padding: 0.2rem 0;
    color: $moderate-blue;
    font-weight: $font-weight600;
    background-color: $white-color;
    box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
      0px 2px 4px rgba(15, 86, 179, 0.2);
    &::before {
      background-color: $white-color;
      color: $moderate-blue;
    }
  }
}
