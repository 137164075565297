@mixin flexBox($direction, $justifyContent, $alignItem) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItem;
}
.hide-login {
  display: none;
}
.login-page-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  .left-side {
    height: 100vh;
    width: 40%;
    background-color: #4b41a9;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .left-header {
      height: 60px;
      width: 100%;
      background-color: #223189;
      .logo {
        margin: 10px;
        margin-left: 10%;
      }
    }
    .banner-wrapper {
      @include flexBox(row, center, center);
      height: 100%;
      .banner-pic {
        width: 372px;
        height: 557px;
      }
    }
    .feedback {
      position: absolute;
      bottom: 10%;
      right: -10%;
      background-color: white;
      box-shadow: 0px 12px 24px 6px rgba(97, 97, 97, 0.08),
        0px 24px 48px 6px rgba(97, 97, 97, 0.08);
      border-radius: 12px;
      width: 387px;
      height: 180px;
      padding: 10px;

      .name {
        color: #0a3977;
      }
    }
  }
  .right-side {
    width: 60%;
    @include flexBox(column, center, center);

    .login-container {
      width: 300px;
      .login__header {
        font-size: 24px;
        font-weight: $font-weight600;
        margin-bottom: 1rem;
      }
      .user__id{
          margin-bottom: 1.5rem;
      }
      .reset-btn {
        color: #0f56b3;
        width: 100%;
        height: 50px;
        border: 1px solid #c0c0c0;
        outline: none;
        border-radius: 12px;
        cursor: pointer;
        font-size: 15px;
        margin: 10px 0px;
      }
      .line-text {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #000;
        line-height: 0.1em;
        margin: 10px 0 20px;

        span {
          background: #fff;
          padding: 0 10px;
        }
      }
    }
    .go-back {
      @include flexBox(row, flex-end, center);
      width: 100%;
      cursor: pointer;
    }
  }
}
