.query-container {
  width: 100%;
  @include flexBox(row, space-between, flex-start);
  margin-top: 20px;
  .query-form {
    @include flexBox(row, center, center);
    width: 48%;
    height: 50%;
    border: 1px solid gray;
    border-radius: 10px;
    background-color: $white-color;
    .form-wrapper {
      width: 90%;
      margin: 15px;
      @include flexBox(column, center, flex-start);
      .form-label {
        height: 50px;
        width: 90%;
        font-size: 20px;
        font-weight: 600;
      }
      .drop-box {
        height: 50px;
        width: 90%;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 20px;
      }
      .text-area {
        height: 250px;
        width: 90%;
        resize: none;
        overflow: auto;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid $lighter-gray;
      }
      .text-area-short {
        height: 200px;
        width: 90%;
        resize: none;
        overflow: auto;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid $lighter-gray;
      }
      .submit-btn {
        background-color: #223189;
        color: white;
        margin-top: 10px;
      }
    }
    .MuiFormControl-root {
      width: 90%;
      margin-bottom: 1rem;
    }
  }
}
