html,
body {
  height: 100%;
  background-color: $bg-color;
}

.dashboard-header {
  width: 100%;
  height: 70px;
  @include flexBox(row, flex-start, center);
  border-bottom: 0.5px solid #e0e0e0;
  .dashboard-label {
    font-weight: 600;
    // margin: 0 1.375rem;
  }
}
.card-container {
  display: grid;
  grid-template-columns: repeat(2, 320px);
  grid-gap: 0px 20px;
  margin: 15px 0px;
}
.responsive__line__wrapper{
  width: 100%;
  height: 420px;
  overflow-x: hidden;
  background-color: $white-color;
  margin-bottom: 2rem;
  border-radius: $border-radius10px;
}
// .body--bg {
//   background-color: $bg-color;
// }

// No data Found
.empty__room__div {
  @include center-div-flex();
  flex-direction: column;
  min-height: 220px;
  height: 100%;
  color: $lighter-gray;

  .bi-x-circle {
    font-size: 25px;
  }
}
