.pagination-container{
    display: flex;
  padding-bottom: 2rem;

  .pagination__left {
    display: flex;
    justify-content: flex-start;

    .pagination__dropdown {
      min-width: 70px;
      background-color: $white-color;
    }

    ul {
      display: flex;

      .showing__page__number {
        @include center-div-flex();
        width: 36px;
        height: 36px;
        cursor: pointer;
        margin-left: 0.9rem;
      }
    }

    .active__page {
      @extend .showing__page__number;
      color: $white-color;
      padding: 0 12px;
      font-weight: $font-weight600;
      border-radius: $border-radius50per;
      background-color: $moderate-blue;
    }

    .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 14px 14px;
    }

    .css-1z7n62 {
      span {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .pagination__right {
    display: flex;
    margin-left: auto;

    .arrow__container {
      @include center-div-flex();
      width: 36px;
      height: 36px;
      padding: 6px 8px;
      color: $moderate-blue;
      background-color: $soft-gray;
      border-radius: $border-radius50per;
      cursor: pointer;

      i {
        -webkit-text-stroke: 0.5px;
      }
    }

    .font--weight {
      font-weight: $font-weight700;
    }
  }

  .MuiSelect-select {
    padding: 8px 14px !important;
  }
}